<template>
  <div class="lost-page">
    <div class="ufo" />
    <div class="light" />
    <div class="text">
      <i class="zero" />
    </div>
    <div class="shadow" />
    <p>
      <span>页面也许被请去喝茶了...</span>
    </p>
  </div>
</template>
<script>
export default {

}
</script>
<style lang='scss' scoped>
.lost-page {
  position: relative;
  width: 100%;
  height: calc(100vh - 130px);
  background: #fff url("../../assets/images/404/404-bg.png") no-repeat center;
  .ufo {
    width: 135px;
    height: 83px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-44%, -130%);
    background: url(../../assets/images/404/ufo.png) no-repeat center;
    animation: ufoScale 0.8s ease;
    transform-origin: top right;
  }
  .light {
    width: 111px;
    height: 129px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-68%, -39%) scale(0);
    background: url(../../assets/images/404/light.png) no-repeat center;
    transform-origin: 80% 0;
    animation: lightScale 0.8s linear 1s;
    animation-fill-mode: forwards;
  }
  .text {
    width: 113px;
    height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -20%);
    opacity: 0;
    background: url(../../assets/images/404/text.png) no-repeat center;
    transform-origin: top center;
    animation: textAnima 0.8s linear 0.6s;
    animation-fill-mode: forwards;
    i {
      display: inline-block;
      height: 100%;
      width: 26px;
      position: absolute;
      left: 50%;
      background: url(../../assets/images/404/zero.png) no-repeat center;
      animation: jump 3s linear infinite;
    }
  }
  .shadow {
    width: 41px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 56%;
    background: url(../../assets/images/404/shadow.png) no-repeat center;
    animation: shadow 3s linear infinite 1s;
    opacity: 0;
    animation-fill-mode: forwards;
  }
  p {
    position: absolute;
    top: 65%;
    left: 50%;
    color: #666;
    font-size: 22px;
    font-weight: bold;
    transform: translate(-50%);
    opacity: 0;
    animation: showText 1s linear 1s;
    animation-fill-mode: forwards;
  }
}

@keyframes ufoScale {
  0% {
    transform: translate(-44%, -130%) scale(0);
  }
  50% {
    transform: translate(-44%, -130%) scale(1.1);
  }
  100% {
    transform: translate(-44%, -130%) scale(1);
  }
}
@keyframes lightScale {
  0% {
    transform: translate(-68%, -39%) scale(0);
  }
  100% {
    transform: translate(-68%, -39%) scale(1);
  }
}
@keyframes textAnima {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes jump {
  0% {
    transform: translate(-50%, -15%);
  }
  50% {
    transform: translate(-50%, 10%);
  }
  100% {
    transform: translate(-50%, -15%);
  }
}
@keyframes shadow {
  0% {
    opacity: 1;
    transform: translate(-50%) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(-50%) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translate(-50%) scale(1);
  }
}
@keyframes showText {
  0% {
    opacity: 01;
  }
  100% {
    opacity: 1;
  }
}
</style>
